<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner">
				<article
					v-if="type == 'out'"
					class="full_view">

					<div class="view_cont_wrap">
						<h3 class="pop_tit pop_tit_logo">{{  $language.common.withdrawal_mafia }}</h3>
						<div class="intro_line_lg"><span class="hide">{{ $language.agreement.dividingLine }}</span></div>
						<p class="pop_desc">
              {{ $language.withdrawal_info.message_01 }}
							<span>{{ $language.withdrawal_info.message_02 }}</span>
						</p>
					</div>

					<div class="btn_wrap">
						<button
							@click="$emit('cancel')"
							class="pop_btn btn_fill_gray"
						>{{  $language.common.cancel }}</button>
						<button
							@click="$emit('click')"
							class="pop_btn btn_fill_blue">{{  $language.common.withdrawal }}</button>
					</div>
				</article>
				<article
					v-else
					class="full_view">

					<div class="view_cont_wrap">
						<h3 class="pop_tit pop_tit_logo">{{  $language.common.logout_mafia }}</h3>
						<div class="intro_line_lg"><span class="hide">{{ $language.agreement.dividingLine }}</span></div>
						<p class="pop_desc">
              {{  $language.common.logout_question }}
						</p>
					</div>

					<div class="btn_wrap">
						<button
							@click="$emit('cancel')"
							class="pop_btn btn_fill_gray"
						>{{  $language.common.cancel }}</button>
						<button
							@click="$emit('click')"
							class="pop_btn btn_fill_blue">{{  $language.common.logout }}</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
	export default {
		name: 'mafia1112'
		, props: ['type']
		, data: function(){
			return {

			}
		}
	}
</script>