<template>
	<div class="section_wrap pt-50 pb-170">
		<div class="container">
			<div class="row">
				<div class="intro_top mt-30 character2 bg_w">
					<div class="text_guide pt-20">
						<h4>{{ $language.withdrawal_info.message_03 }}</h4>
					</div>
					<div class="text_guide_desc pt-15">
						<p class="text">{{ $language.withdrawal_info.message_04 }}</p>
						<p class="point_text">{{ $language.withdrawal_info.message_05 }}</p>
						<p class="text">{{ $language.withdrawal_info.message_06 }}</p>
					</div>
				</div>
				<ul class="quit_list">
					<li
						v-if="items_wallet.length > 0"
					>
						<div class="bright_checks quit_agree">
							<input
								v-model="is_agree"
								type="checkbox" id="nft3"
							>
							<label for="nft3">
								<p>{{ $language.withdrawal_info.message_07 }} </p>
							</label>
						</div>
					</li>
					<li
						v-else
					>
						<div class="bright_checks quit_agree">
							<input
								v-model="is_agree"
								type="checkbox" id="nft1"
							>
							<label for="nft1">
								<p>{{ $language.withdrawal_info.message_07 }}</p>
							</label>
						</div>
					</li>

				</ul>
				<div class="pt-30">
					<div class="btn_area">
						<button
							:disabled="!is_out"
							class="btn_l btn_fill_blue disabled_gray"
							@click="is_1112 = true"
						>{{  $language.common.withdrawal }} </button>
					</div>
				</div>
			</div>
		</div>

		<mafia1112
			v-if="is_1112"
			type="out"
			@cancel="is_1112 = !is_1112"
			@click="checkOut"
		></mafia1112>
	</div>
</template>

<script>
	import mafia1112 from "@/view/Mypage/mafia111-2"
	export default {
		name: 'mafia1111'
		, props: ['user']
		, components: {mafia1112}
		, data: function(){
			return {
				program: {
					name: this.$language.common.withdrawal_account
					, title: this.$language.state_02.CA00300006
					, type: 'mypage'
				}
				, is_1112: false
				, is_wallet: true
				, items: []
				, items_wallet: []
				, is_agree: false
				, member_social_certfc_code: ''
				, social_certfc_code: ''
			}
		}
		, computed: {

			is_out: function(){
				let t = false
					if(this.is_agree){
						t = true
					}
				return t
			}
		}
		, methods: {

			postOut: async function(e){

				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_member_out
						, data: {
							member_number: this.user.member_number
							, member_social_certfc_code: this.member_social_certfc_code
							, social_certfc_code: e.access_token
						}
						, type: true
					})
					if(result.success){
						this.$bus.$emit('logout')
					}else{
						this.$bus.$emit('notify',  { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
					this.is_1112 = false
				}
			}


			, postLogout: async function(){
				console.log('postLogout')
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_logout
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						await this.$encodeStorage.removeSessionAt()
						await this.$encodeStorage.removeItem('login_info')
						document.location.href = '/'
					}else{
						throw result.message
					}
				}catch(e){
					await this.logout()
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_wallet_list
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.items_wallet = result.data.wallet_list
					}else{
						this.$bus.$emit('notify',  { type: 'error', message: result.message})
					}
				}catch (e){
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
					if(process.env.VUE_APP_TYPE == 'sample'){
						let result = this.$sample.getSampleData('wallet')
						if(result.success){
							this.items = result.data
							for(let i = 0; i < result.data.length; i++){
								if(Number(result.data[i].amount) > 0){
									this.is_wallet = false
									break
								}
							}
						}
					}
				}
			}
			, checkOut: function(){

				if(this.member_social_certfc_code == 'MB00400002'){
					try{
						this.$common.inAppWebviewCommunity('android', 'sign_in_apple')
					}catch(e){
						console.log('google login error', e)
					}
				}else{
					this.postOut({ social_certfc_code: ''})
				}
			}
		}
		, created() {
			this.$emit('onLoad', this.program)
			this.getData()

			window.sign_in_social = (e) => {
				console.log('out window.sign_in_social', e)
				try{
					this.postOut(e)
				}catch (e) {
					console.log(e)
				}
			}

			let login_info = this.$encodeStorage.getItem('login_info')
			console.log('login_info', login_info)
			if(login_info){

				switch (login_info.type){
					case 'kakao':
						this.member_social_certfc_code = 'MB00400001'
						break
					case 'google':
						this.member_social_certfc_code = 'MB00400003'
						break
					case 'apple':
						this.member_social_certfc_code = 'MB00400002'
						break
					default:
						return false
				}
			}
		}
	}
</script>